<template>
  <v-dialog
    width="700"
    persistent
    transition="slide-x-transition"
    v-model="getModalViewFormaDePagamento"
  >
    <v-card class="expande-horizontal wrap">
      <div class="expande-horizontal px-6 py-3">
        <v-btn dark @click="fechaModalViewFormaDePagamento" icon>
          <v-icon color="grey">
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <span style="font-size: 17pt;" class="fonte green--text">
          Forma de Pagamento
        </span>
        <div class="expande-horizontal"></div>
        <v-btn class="elevation-3" icon @click="fechaModalViewFormaDePagamento">
          <v-icon color="red darken-3">
            mdi-close
          </v-icon>
        </v-btn>
      </div>

      <div class="expande-horizontal wrap py-6 pb-0">
        <v-flex xs12>
          <v-form ref="form">
            <v-flex class="px-7" xs12>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                Nome
              </span>
              <v-text-field
                v-model="getFormaDePagamento.nome"
                dense
                solo
                flat
                outlined
                :color="$theme.primary"
                label="ex: pix"
              ></v-text-field>
            </v-flex>

            <v-flex class="px-7" xs12>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                Descrição
              </span>
              <v-text-field
                v-model="getFormaDePagamento.descricao"
                dense
                solo
                flat
                outlined
                :color="$theme.primary"
                label="ex: Recebe pagamentos via pix"
              ></v-text-field>
            </v-flex>

            <v-flex class="px-7" xs12>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                Disponivel?
              </span>
              <v-switch
                v-model="getFormaDePagamento.disponivel"
                :color="$theme.primary"
              ></v-switch>
            </v-flex>
          </v-form>
        </v-flex>
      </div>

      <div class="expande-horizontal">
        <v-btn large dark @click="validate" tile block color="green">
          <span class="fonte"> Salvar </span>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getModalViewFormaDePagamento", "getFormaDePagamento"])
  },
  methods: {
    ...mapActions(["criarFormaDePagamento", "fechaModalViewFormaDePagamento"]),
    validate() {
      if (this.$refs.form.validate()) {
        this.criarFormaDePagamento(this.getFormaDePagamento);
      }
    }
  }
};
</script>
