<template>
  <layout :title="$route.name" :options="options">
    <v-flex xs12 class="pa-3">
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left fonte">
                NOME
              </th>
              <th class="text-left fonte">
                DESCRIÇÃO
              </th>
              <th class="text-left fonte">
                DISPONIVEL
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in getFormasDePagamentos"
              :key="item._id"
              @click="abreModalViewFormaDePagamento(item)"
            >
              <td class="font-weight-bold">{{ item.nome }}</td>
              <td class="">{{ item.descricao }}</td>
              <td class="">{{ item.disponivel ? "sim" : "não" }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-flex>
    <ModalViewFormaDePagamento />
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalViewFormaDePagamento from "./components/modalViewFormaDePagamento.vue";
export default {
  data() {
    return {
      options: [
        {
          nome: "Cadastrar Forma de Pagamento",
          action: () => this.abreModalViewFormaDePagamento({})
        }
      ]
    };
  },
  components: {
    ModalViewFormaDePagamento
  },
  computed: {
    ...mapGetters(["getFormasDePagamentos"])
  },
  methods: {
    ...mapActions(["listarFormasDePagamentos", "abreModalViewFormaDePagamento"])
  },
  created() {
    this.listarFormasDePagamentos();
  }
};
</script>
